import { createAction } from 'redux-actions';

const FETCH_CUSTOMERS = '@customers/FETCH_CUSTOMERS';
const PUT_CUSTOMERS = '@customers/PUT_CUSTOMERS';

const FETCH_DETAILS = '@customers/FETCH_DETAILS';
const PUT_DETAILS = '@customers/PUT_DETAILS';

const FETCH_CUSTOMERS_TAGS = '@customers/FETCH_CUSTOMERS_TAGS';
const PUT_CUSTOMERS_TAGS = '@customers/PUT_CUSTOMERS_TAGS';

export const fetchCustomers = createAction(FETCH_CUSTOMERS);
export const putCustomers = createAction(PUT_CUSTOMERS);

export const fetchDetails = createAction(FETCH_DETAILS);
export const putDetails = createAction(PUT_DETAILS);

export const fetchCustomersTags = createAction(FETCH_CUSTOMERS_TAGS);
export const putCustomersTags = createAction(PUT_CUSTOMERS_TAGS);
