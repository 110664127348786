import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { customers as api } from '../api';
import { customers as customersActions, snackbar as snackbarActions } from '../redux/actions';

function* fetchDetails({ payload: customerId }) {
  try {
    const details = yield call(api.fetchDetails, customerId);
    yield put(customersActions.putDetails(details));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch customers details error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchCustomersList({
  payload: {
    page, perPage, filters, sort,
  },
}) {
  try {
    const [customers, total] = yield call(api.fetchCustomers, {
      page,
      perPage,
      filters,
      sort,
    });

    yield put(customersActions.putCustomers({ list: customers, total }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch customers list error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchCustomersTags() {
  try {
    const tags = yield call(api.fetchTags);

    yield put(customersActions.putCustomersTags(tags));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch customers tags error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

export default function* watcher() {
  yield takeLatest(customersActions.fetchDetails.toString(), fetchDetails);
  yield takeLatest(
    customersActions.fetchCustomers.toString(),
    fetchCustomersList,
  );
  yield takeLatest(customersActions.fetchCustomersTags.toString(), fetchCustomersTags);
}
