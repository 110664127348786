import {
  FETCH_CUSTOMERS_ENDPOINT,
  FETCH_CUSTOMER_ENDPOINT,
  FETCH_CUSTOMER_TAGS,
} from '../constants/endpoints';
import { convertFiltersToQueryString, convertSortToQueryString } from '../help/convert';


import axios from './axios';

const fetchCustomers = async ({
  perPage = 10,
  page = 1,
  filters = {},
  sort = {},
}) => {
  const { tags, ...otherFilters } = filters;
  const filtersQueryString = convertFiltersToQueryString(otherFilters);

  let tagsQueryString = '';
  if (Array.isArray(tags) && tags.length > 0) {
    tagsQueryString = tags.map((v) => `&tags[]=${encodeURIComponent(v)}`).join('');
  }

  const sortQueryString = convertSortToQueryString(sort);

  const queryParams = `page=${page}&per_page=${perPage}${filtersQueryString}${tagsQueryString}${sortQueryString}`;

  const {
    data: { customers, total },
  } = await axios.post(
    `${FETCH_CUSTOMERS_ENDPOINT}?${queryParams}`
  );

  return [customers, total];
};

const fetchDetails = async (id) => {
  const { data: list } = await axios.get(
    `${FETCH_CUSTOMER_ENDPOINT}/${id}`,
  );

  return list;
};

const fetchTags = async () => {
  const { data } = await axios.get(
    `${FETCH_CUSTOMER_TAGS}`,
  );

  return data;
};

export default {
  fetchCustomers,
  fetchDetails,
  fetchTags,
};
